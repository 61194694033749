import React from 'react'
import { Seo } from '../components/Seo'

import Layout from '../components/layout'
import Members from '../components/Members'
import BannerFamily from '../components/BannerFamily'

const Family = () => {
  return (
    <Layout>
      <Seo />
      <BannerFamily />
      <Members />
    </Layout>
  )
}

export default Family
