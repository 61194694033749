import React from 'react'

const BannerLanding = () => (
  <section id="banner" className="style3">
    <div className="inner">
      <header className="major">
        <h1>The Bunch</h1>
      </header>
      <div className="content">
        <p>
          Here is a gist of all important in house members of bloomB family.
        </p>
      </div>
    </div>
  </section>
)

export default BannerLanding
